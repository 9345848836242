.todo-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  cursor: pointer;
}

/* Add this new style for the icon container */
.todo-options {
  margin-left: auto; /* Push the container to the far right */
  display: flex;
  align-items: center;
}


.todo-item.completed .todo-title {
  text-decoration: line-through;
  color: #999;
}

.todo-title {
  margin-left: 10px;
}

.todo-item:hover .edit-icon, .todo-item:hover .time-icon, .todo-item:hover .calendar-icon, .todo-item:hover .delete-icon, .todo-item:hover .time-icons, .todo-item:hover .bookmark-icon{
  visibility: visible;
  color: #999;
  cursor: pointer;
}

.edit-icon, .time-icon, .calendar-icon, .delete-icon, .bookmark-icon {
  visibility: hidden; /* Hide the icon */
  margin-left: 8px;
  cursor: pointer;
}

.time-icons {
  display: flex;
  align-items: center;
  color: #999;
  visibility: hidden;
}

.react-datepicker-wrapper {
  visibility: visible; 
  /* added visibility once as it was not allowing me to keep it empty */
}

/* Hide the default checkbox */
.todo-item-checkbox {
  display: none;
}

/* Custom checkbox style */
.custom-checkbox-label {
  position: relative;
  cursor: pointer;
  width: 20px; /* Custom width */
  height: 20px; /* Custom height */
  background-color: #f0f0f0; /* Light grey background */
  display: inline-block;
  border-radius: 4px; /* Optional: for rounded corners */
  margin-right: 12px;
  padding: 11px; /* Increased padding */
  box-sizing: border-box; /* Ensures padding doesn't affect overall dimensions */
}

/* Style for when custom checkbox is checked */
.todo-item-checkbox:checked + .custom-checkbox-label {
  background-color: #007bff; /* Bootstrap primary color for example */
}

/* Optional: Custom checkmark style */
.todo-item-checkbox:checked + .custom-checkbox-label:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.editable-title-input{
  width: 100%;
}

.open-btn-mobile {
  display: none;
}

.mmodal-content-inner {
  background-color: #ffffff;
  margin: 16px;
  overflow-y: auto; /* Allow scrolling if content overflows */
}

/* Task Title Styling */
.todo-title-mobile {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 8px; /* Add some space below the title */
}

.todo-dueOn-mobile{
  font-size: .9rem;
  font-weight: 500;
}

/* Action Buttons Styling */
.todo-actions-mobile button {
  background-color: #f0f0f0;
  border: none;
  padding: 8px 16px;
  margin-right: 8px;
  margin-top: 16px;
  cursor: pointer;
  font-size: .8rem;
  font-weight: bold;
  text-transform: uppercase;
  overflow: auto;
}

.time-dropdown{
    padding: 5px;
    margin-right: 8px;
    border: 1px solid #d0d0d0;
    font-size: .875rem;
    cursor: pointer;
    color: #777;
}

.time-adjustments-mobile {
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Rich Text Editor Specific Styling */
.todo-content-mobile {
  padding: 8px; /* Padding inside the rich text editor */
}


@media (max-width: 768px) {
  .todo-item{
    position: relative;
  }
  
  .todo-options {
    position: absolute;
    right: 0;
  }

  .todo-title{
    margin-right: 50px;
  }

  .editable-title-input{
    margin-right: 60px;
  }

  /* Show the "Open" button only on mobile */
  .open-btn-mobile {
    display: block; /* Show the Open button on mobile */
    position: absolute; /* Position it according to your design */
    right: 2px; /* Example positioning */
    background-color: #fff;
    border-color: transparent;
    color: #004c9d;
    font-size: .875rem;
    text-decoration: underline;
  }
  
  /* Hide the FontAwesome icons on mobile */
  .edit-icon, .time-icon, .calendar-icon, .delete-icon, .bookmark-icon, .time-icons {
    display: none;
  }

}