/* AccountView.css */
.account-view {
    padding: 20px;
    border: 1px solid #ccc;
    margin: 20px;
    border-radius: 8px;
  }
  
  .account-view h1 {
    margin-bottom: 20px;
  }
  
  .account-view p {
    margin-bottom: 10px;
  }
  
  .account-view button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .account-view button:hover {
    background-color: #0056b3;
  }
  