.todo-section {
  position: relative;
  z-index: 20;
  flex: 1 1 0%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Stack children vertically */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling within the section itself */
}
.todo-sub-section {
    position: relative;
    display: flex;
    height: 100vh;
    align-items: flex-start;
    overflow-y: auto;
    scrollbar-width: none;
    flex: 1; /* Take up all available space */
    display: flex;
    flex-direction: column; /* Stack children vertically */
    overflow: hidden; /* Prevent scrolling */
}
.todo-sub-section .top-head {
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 1 1 0%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  overflow-y: auto;
}

.top-head {
  position: sticky;
  top: 0; /* Stick to the top of the closest scrolling ancestor */
  z-index: 10; /* Ensure it layers above the scrollable content */
  background-color: white; /* Optional: Ensure text doesn't bleed through */
}

.todo-form-wrapper {
  display: flex;
  padding: 10px 0px;
  /* padding-top: 10px; */
}

.todo-form{
  background: #f2f8ff;
  border-radius: 40px;
  border-top-style: none;
  display: flex;
  justify-content: center; /* Horizontally center the children */
  align-items: center; /* Vertically center the children */
  width: 100%;
}

.todo-form input {
  width: 100%;
  height: 20px;
  border: 0;
  background: #f2f8ff;
  border-radius: 40px;
  border-top-style: none;
  padding: 20px 24px;
  margin: 2px;
}

.todo-form button {
  height: 50px;
  width: auto;
}

.todo-items-list {
  overflow-y: auto; /* Enable vertical scrolling */
  padding-bottom: 20px;

   /* Hide scrollbar for Webkit browsers */
   &::-webkit-scrollbar {
    display: none;
    }
  
    /* For Firefox */
    scrollbar-width: thin; /* Make scrollbar thinner */
    scrollbar-color: transparent transparent; /* Make the scrollbar transparent */
  
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;    
}