/* .navigation-bottombar {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    height: 50px; */
    /* $primary-color-95: #f2f8ff; */
    /* background: #f2f8ff;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index:
  } */

  .navigation-bottombar {
    display: flex; /* Use flexbox for the layout */
    justify-content: space-between; /* Distribute items evenly */
    align-items: center; /* Center items vertically */
    padding: 8px 16px; /* Padding for some breathing room */
    height: 50px; /* Fixed height for the bar */
    background: #f2f8ff; /* Background color */
    width: 100%; /* Full width */
    position: fixed; /* Fixed at the bottom */
    bottom: 0; /* Stick to the bottom */
    left: 0; /* Align to the left edge */
    z-index: 1000; /* Ensure it's on top of other content */
  }

/* Hide on screens wider than 768px */
@media (min-width: 768px) {
    .navigation-bottombar {
      display: none;
    }
  }
