// In styles/_elements.scss
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// Setting Roboto as the font for headings
h1, h2, h3, h4, h5, h6 {
    font-family: 'Roboto', sans-serif;
    color: #333;
}
  
// Setting Open Sans as the default font for body text
body {
    font-family: 'Open Sans', sans-serif;
    color: #333;
    background-color: #fff;
}

button{
    font-family: 'Roboto', sans-serif;
}

span{
    font-family: 'Roboto', sans-serif;

    &.highlight {
      background-color: $primary-color;
      color: #999;
    }
}

// Input text fields, you might also include states like hover, focus, and error
input[type="text"],
input[type="email"],
input[type="password"] {
  padding: 0 30px;
  border-radius: $border-radius;
  border: 1px solid darken($primary-button-color, 10%);
  font-size: $font-size-base;
  font-family: 'Roboto', sans-serif;

  &:focus {
    outline: none;
    border-color: $primary-color;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.5);;
  }

  &.error {
    border-color: $error-color;
  }
}

div{
    font-family: 'Roboto', sans-serif;
}

