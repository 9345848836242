.cimodal-overlay {
  position: absolute; /* Relative to .calendar-view */
  top: 0;
  left: 0;
  width: 100%; /* Match the width of .calendar-view */
  height: 100%; /* Match the height of .calendar-view */
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
}
  
  .cimodal-content {
    background-color: white;
    width: fit-content;
    height: fit-content;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;
    padding: 16px;
    font-family: 'Roboto', sans-serif;;
    color: #333;
    max-height: 60%;
    max-width: 50%;

    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
    display: none;
    }
  
    /* For Firefox */
    scrollbar-width: thin; /* Make scrollbar thinner */
    scrollbar-color: transparent transparent; /* Make the scrollbar transparent */
  
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;      
  }

  .cimodal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 28px;
    color: #000;
    font-weight: 800;
    cursor: pointer;
    display: none; /* Hide by default */
  }
  
  /* Show the close button only on phone-sized devices */
  @media (max-width: 768px) {
    .cimodal-close-btn {
      display: block;
    }
  }