.navigation-sidebar {
    display: none;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
    width: 64px;
    /* $primary-color-95: #f2f8ff; */
    background: #f2f8ff;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;
  }

  @media (min-width: 768px) {
    .navigation-sidebar {
      display: flex;
    }
  }