.sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sign-in-container .signIn-btn {
  border-radius: 8px;
  border-width: 2px;
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  background: #3396A1;
  border-bottom: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  border-top: 2px solid transparent;
  color: #fff;
}

.sign-in-container .google-login {
  margin-bottom: 10px;
  width: 100%;
  border-bottom: 2px solid #3396A1;
  border-left: 2px solid #3396A1;
  border-right: 2px solid #3396A1;
  border-top: 2px solid #3396A1;
  text-align: left;
  color: #3396A1;
  background: transparent;
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
}

.sign-in-container .signUp-btn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: unset;
  border:unset;
  font-weight: 300;
  font-size: 14px;
}
.signUp-btn span {
padding-left: 4px;
font-weight: 500;
}

.signUp-btn span:hover {
  text-decoration: underline;
}

.sign-in-container .signIn-btn:hover {
  border-bottom: 2px solid #3396A1;
  border-left: 2px solid #3396A1;
  border-right: 2px solid #3396A1;
  border-top: 2px solid #3396A1;
  background: transparent;
  color: #000;
}

.sign-in-container .signUp-btn:hover {
  background: unset;
}

.sign-in-container button:hover {
  background: #EEEEEE;
  border-color: #EEEEEE;
}

.sign-in-container input {
  margin-top: 8px;
  display: inline-flex;
  border-radius: 6px;
  padding: 16px;
  font-size: 14px;
  line-height: 19px;
  font-weight: 350;
  color: #3396A1;
  border-bottom: 1px solid #3396A180;
  border-left: 1px solid #3396A180;
  border-right: 1px solid #3396A180;
  border-top: 1px solid #3396A180;
}

.sign-in-container form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.forgot-password {
  text-align: right;
  margin-top:10px;
  font-size: 14px;
  cursor: pointer;
  color: #000;
  text-decoration: none;
}
.forgot-password:hover {
  text-decoration: underline;
}