.App {
  flex-direction: row;
  height: 100vh;
}

.loading-container{
  height: 100vh;
  width: 100vw;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
}