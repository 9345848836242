.hashtag-filter-bar {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    gap: 10px;
    font-size: .825rem;
    max-width: 90vw;
    padding-bottom: 30px;
    height: fit-content;


    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
    display: none;
    }
  
    /* For Firefox */
    scrollbar-width: thin; /* Make scrollbar thinner */
    scrollbar-color: transparent transparent; /* Make the scrollbar transparent */
  
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;    
  }
  
  .hashtag {
    cursor: pointer;
    background: #e5f2ff; /* $primary-color-90: #e5f2ff; */
    padding: 5px 10px;
    border-radius: 15px;
    user-select: none;
    height: fit-content;
  }
  
  .hashtag.selected {
    background: #007bff;
    color: #fff;
  }

  .select-clear-btn{
    cursor: pointer;
    background: #ff4081; /* $primary-color-90: #e5f2ff; */
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    user-select: none;
    box-shadow: none;
    white-space: nowrap;
    height: fit-content;
  }