// In styles/_custom-classes.scss
// Define a function for generating a darker shade for hover states
@function darken-color($color, $amount) {
  @return darken($color, $amount);
}

.primary-button {
    background-color: $primary-button-color;
    color: #000;
    border-radius: $border-radius;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    padding: 0 30px;
    

    &:hover {
      background-color: darken-color($primary-button-color, 5%);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px rgba($primary-button-color, 0.5);
    }
}

.button-tab{
  padding: 0;
  margin: 0 12px;
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color:#88a7aa;
}

.button-tab.active,
.button-tab:hover,
.button-tab:focus {
  border-bottom: 2px solid #ff4081;
  color: #000;
}

.font-base {
  font-size: 1rem;
}

.font-lg{
  font-size: 1.125rem;

}

.font-sm{
  font-size: 0.875rem;
}

.secondary-text {
  color: #999;
}

.toast-error {
  color: $error-color;
}

.toast-success {
  color: #23d160;
}

.toast-warning {
  color: #ffdd57;
}

.toast-container {
  .toast {
    display: flex;
    align-items: center;
    border-radius: $border-radius;
    padding: 10px;
    margin-bottom: 10px;
  }

  .toast-body {
    flex-grow: 1;
  }

  .btn-close {
    background: none;
    border: none;
    padding: 0;
  }
}

.dropdown {
  padding: 0 12px;
  border-radius: $border-radius;
  background-color: $accent-color;
  border: 1px solid darken($accent-color, 10%);

  &:hover {
    border-color: darken($accent-color, 20%);
  }

  // Style for the dropdown options
  .dropdown-option {
    padding: 10px;
    &:hover {
      background-color: $primary-color;
      color: $light-text-color;
    }
  }
}

// .tag {
//   display: inline-block;
//   padding: 5px 10px;
//   border-radius: $border-radius;
//   background-color: $warning-color;
//   color: $text-color;
//   margin-right: 5px;
//   font-size: $font-size-sm;

//   &.success {
//     background-color: $success-color;
//   }

//   &.error {
//     background-color: $error-color;
//   }
// }

// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   z-index: 1000;
// }

// .modal-content {
//   background-color: $light-text-color;
//   padding: 20px;
//   border-radius: $border-radius;
//   box-shadow: $box-shadow;
// }

// .form-control {
//   display: block;
//   width: 100%;
//   padding: 0 12px;
//   font-size: $font-size-base;
//   border-radius: $border-radius;
//   border: 1px solid darken($accent-color, 10%);
//   transition: border-color 0.3s ease;

//   &:focus {
//     outline: none;
//     border-color: $primary-color;
//   }

//   &.error {
//     border-color: $error-color;
//   }
// }

// .form-group {
//   margin-bottom: 15px;
// }

// .form-label {
//   display: block;
//   margin-bottom: 5px;
// }

// .navbar {
//   background-color: $primary-color;
//   padding: 10px 15px;

//   .nav-item {
//     display: inline-block;
//     margin: 0 10px;

//     a {
//       text-decoration: none;
//       color: $light-text-color;

//       &:hover {
//         color: darken($light-text-color, 15%);
//       }
//     }
//   }
// }

// .sidebar {
//   background-color: darken($accent-color, 10%);
//   min-width: 200px;
//   padding: 10px;

//   .sidebar-item {
//     padding: 10px;
//     cursor: pointer;

//     &:hover {
//       background-color: lighten($accent-color, 5%);
//     }
//   }
// }

// .tooltip {
//   position: relative;
//   display: inline-block;

//   &:hover .tooltip-text {
//     visibility: visible;
//   }

//   .tooltip-text {
//     visibility: hidden;
//     background-color: $text-color;
//     color: $light-text-color;
//     text-align: center;
//     border-radius: $border-radius;
//     padding: 5px;
//     position: absolute;
//     z-index: 1000;
//     bottom: 100%;
//     left: 50%;
//     transform: translateX(-50%);
//   }
// }

// .accordion {
//   .accordion-header {
//     padding: 0 12px;
//     background-color: $accent-color;
//     cursor: pointer;
//     border: none;
//     margin-bottom: 2px;
//     transition: background-color 0.3s ease;

//     &:hover {
//       background-color: darken($accent-color, 5%);
//     }
//   }

//   .accordion-content {
//     padding: 15px;
//     border: 1px solid $accent-color;
//     display: none;
//   }

//   .accordion-item.active .accordion-content {
//     display: block;
//   }
// }

