$primary-color: #007bff;
$primary-color-10: #1a88ff;
$primary-color-20: #3395ff;
$primary-color-30: #4da3ff;
$primary-color-40: #66b0ff;
$primary-color-50: #80bdff;
$primary-color-60: #99caff;
$primary-color-70: #b2d7ff;
$primary-color-80: #cce5ff;
$primary-color-90: #e5f2ff;
$primary-color-95: #f2f8ff;
$black: #000000;
$white: #ffffff;

$accent-color: #ff4081;
$accent-color-10: #ff538d;
$accent-color-20: #ff669a;
$accent-color-30: #ff79a6;
$accent-color-40: #ff8cb3;
$accent-color-50: #ff9fc0;
$accent-color-60: #ffb2cc;
$accent-color-70: #ffc5d9;
$accent-color-80: #ffd8e5;
$accent-color-90: #ffebf2;


$primary-button-color: #e6f2f3;
$border-radius: 40px;

$error-color: #ff4081; // Error messages and icons
$success-color: #007bff; // Success state, e.g., for toast messages
$warning-color: #ffdd57; // Warning state or important tags

$font-size-base: 1rem;
$light-text-color: #999;
$text-color: #333;