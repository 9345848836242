.todo-item-content-container {
    height: 100%; /* Make the container take full height */
  }
  
  .ql-container {
    height: 100%; /* Make Quill container take full height */
  }
  
  .ql-editor {
    min-height: 100%; /* Make the editor take up the available space */
  }

  .quill{
    height: 94%;
  }