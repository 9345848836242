/* Style for the checkbox */
.history-task-item input[type="checkbox"] {
    margin: 0; 
    /* Additional styles for the checkbox */
}

.task-date{
    font-size: 1.05em;
    font-weight: bold;
    margin-left: 4px;
    margin-bottom: 0px;
}

/* Ensure the text doesn't overflow and stays in the container */
.task-title,
.task-content {
    white-space: normal; /* Allow the text to wrap */
    overflow-wrap: break-word; /* Ensure long words do not overflow */
    word-wrap: break-word; /* Deprecated version of overflow-wrap for older browsers */
    word-break: break-word; /* Ensures the text breaks to prevent overflow */
}

.task-title{
    font-size: 1em;
    margin-left: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    margin-right: 12px;

}

.task-content{
    font-size: .8em;
    margin-left: 32px;
    margin-top: 4px;
    
}

.task-options{
    margin-left: 12px;
    cursor: pointer;

}

.copy-task-dropdown{
  cursor: pointer;
}

.task-edit-modal {
    background: #fff;
    padding: 20px;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    width: 100%;
    max-width: 500px; /* Adjust width as necessary */
    margin: auto; /* Center the modal */
  }
  
  .task-edit-modal label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .task-edit-modal input[type="checkbox"] {
    margin-left: 10px;
  }
  
  .task-edit-modal select {
    display: block;
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .task-edit-modal button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    margin-right: 10px;
    cursor: pointer;
    font-weight: bold;
  }
  
  /* Style for the save button */
  .task-edit-modal button:first-of-type {
    background-color: #007bff;
    color: white;
  }
  
  /* Style for the cancel button */
  .task-edit-modal button:last-of-type {
    background-color: #f0f0f0;
    color: #333;
  }
  
  /* Style to align buttons to the right and bottom */
  .task-edit-modal > button {
    float: right;
    margin-top: 20px; /* Add some space above the buttons */
  }
  
  /* Clear float */
  .task-edit-modal::after {
    content: "";
    display: block;
    clear: both;
  }
  