.history-view{
    max-width: 90%;
    /* max-width: 800px; */
    min-width: 700px;
    background-color: #f2f8ff;
    padding: 0 0 24px 16px;
    overflow-y: auto;

    /* Hide scrollbar for Webkit browsers */
    &::-webkit-scrollbar {
    display: none;
    }
  
    /* For Firefox */
    scrollbar-width: thin; /* Make scrollbar thinner */
    scrollbar-color: transparent transparent; /* Make the scrollbar transparent */
  
    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;
}

.tabs-wrapper{
    max-height: 200px;
    height: fit-content;
}

@media (max-width: 768px) {
    .history-view{
        background-color: unset;
        min-width: 100vw;
        overflow-y: auto;
        max-width: 100vh;
        max-width: 100vw;
        margin-bottom: 50px;
    }

    .task-list{
        padding-bottom: 30px;
    }
  }