.todo-tabs {
  display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}

.todo-tabs .heading-todo {
  font-size: 23px;
  line-height: 30px;
  font-weight: 500;
}