/* LoginScreen.css */
.login-screen {
  display: flex;
  height: 100vh;
}

.login-image {
  width: 100%;
  height: 100%;
  flex: 1 ;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-wrapper {
  padding: 0 64px;
  max-width :576px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex: 1 ;
}

.login-head {
  margin-top: auto;
    margin-bottom: auto;
    width: 100%;
}

.heading-text{
  margin-bottom: 32px;
}

.heading-text h1 {
  margin-bottom: 0;
  font-size: 23px;
  line-height: 30px;
  font-weight: 400;
}

.heading-text p {
  margin-top: 0;
  font-size: 14px;
  line-height: 19px;
  color: #a59a9a;
  padding: 5px 0;
}

/* .form-footer {
  display: flex;
  flex-direction: row;
} */

.separator {
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
  position: relative;
  width: 100%;
}

.separator::before,
.separator::after {
  content: '';
  display: inline-block;
  width: 40%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  top: 50%;
}

.separator::before {
  left: 0;
}

.separator::after {
  right: 0;
}

.separator span {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
  top: -8px;
}

@media only screen and (max-width: 767px) {
  .login-screen {
    flex-direction: column;
  }
}
