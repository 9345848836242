@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", sans-serif;
  color: #333;
}

body {
  font-family: "Open Sans", sans-serif;
  color: #333;
  background-color: #fff;
}

button {
  font-family: "Roboto", sans-serif;
}

span {
  font-family: "Roboto", sans-serif;
}
span.highlight {
  background-color: #007bff;
  color: #999;
}

input[type=text],
input[type=email],
input[type=password] {
  padding: 0 30px;
  border-radius: 40px;
  border: 1px solid #c4e0e2;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
}
input[type=text]:focus,
input[type=email]:focus,
input[type=password]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}
input[type=text].error,
input[type=email].error,
input[type=password].error {
  border-color: #ff4081;
}

div {
  font-family: "Roboto", sans-serif;
}

.primary-button {
  background-color: #e6f2f3;
  color: #000;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  padding: 0 30px;
}
.primary-button:hover {
  background-color: #d5e9eb;
}
.primary-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(230, 242, 243, 0.5);
}

.button-tab {
  padding: 0;
  margin: 0 12px;
  font-size: 16px;
  cursor: pointer;
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  color: #88a7aa;
}

.button-tab.active,
.button-tab:hover,
.button-tab:focus {
  border-bottom: 2px solid #ff4081;
  color: #000;
}

.font-base {
  font-size: 1rem;
}

.font-lg {
  font-size: 1.125rem;
}

.font-sm {
  font-size: 0.875rem;
}

.secondary-text {
  color: #999;
}

.toast-error {
  color: #ff4081;
}

.toast-success {
  color: #23d160;
}

.toast-warning {
  color: #ffdd57;
}

.toast-container .toast {
  display: flex;
  align-items: center;
  border-radius: 40px;
  padding: 10px;
  margin-bottom: 10px;
}
.toast-container .toast-body {
  flex-grow: 1;
}
.toast-container .btn-close {
  background: none;
  border: none;
  padding: 0;
}

.dropdown {
  padding: 0 12px;
  border-radius: 40px;
  background-color: #ff4081;
  border: 1px solid #ff0d5f;
}
.dropdown:hover {
  border-color: #d9004a;
}
.dropdown .dropdown-option {
  padding: 10px;
}
.dropdown .dropdown-option:hover {
  background-color: #007bff;
  color: #999;
}

