/* Dashboard.css */

.dashboard {
  display: flex;
  height: 100vh;
  display: flex;
  overflow-y: hidden; /* Allow vertical scroll if content overflows */
  height: 100%;
  min-height: 100vh;
  width: 100%;
  /* Ensure dashboard content starts after the sidebar if it's positioned outside the dashboard */
}

/* Additional styling for children of main-content for layout and spacing */
.main-content > * {
  padding: 20px; /* Example padding, adjust as needed */
}
