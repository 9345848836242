/* Base icon styles, optimized for mobile first */
/* .nav-icon {
  margin: 8px 10px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: space-around;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
} */

/* Base icon styles, optimized for mobile first */
.nav-icon {
  width: 25px; /* Set a default size for mobile */
  height: 25px; /* Set a default size for mobile */
  background-size: cover; /* Ensure the icon covers the area */
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

/* Adjust icon sizes and spacings for desktop and tablet */
@media (min-width: 768px) {
  .nav-icon {
    margin-bottom: 40px;
    width:25px; /* Larger size for desktop */
    height: 25px; /* Larger size for desktop */
  }
}

.icon-calendar-todo {
  background-image: url('assets/dashboardIcons/calendar-todo-icon.svg');
}

.icon-history {
  background-image: url('assets/dashboardIcons/history-icon.svg');
}

.icon-planning {
  background-image: url('assets/dashboardIcons/planning-icon.svg');
}

.icon-analytics {
  background-image: url('assets/dashboardIcons/analytics-icon.svg');
}

.icon-account {
  background-image: url('assets/dashboardIcons/account-icon.svg');
}

.icon-logout {
  background-image: url('assets/dashboardIcons/logout-icon.svg');
}
