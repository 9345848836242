.calendar-view {
  z-index: 10;
  height: 100%;
  height: 100vh;
  flex-basis: 30%;
  overflow-y: auto;
  max-width: 450px;
  min-width: 275px;
  position: relative; /* The parent container must be positioned */

  /* Hide scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
  display: none;
  }

  /* For Firefox */
  scrollbar-width: thin; /* Make scrollbar thinner */
  scrollbar-color: transparent transparent; /* Make the scrollbar transparent */

  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;    

  @media (max-width: 768px) {
    display: none; /* Hide calendar view on mobile devices */
  }
}

.integrate-calendar-modal{
  position: absolute;
  width: 100%;  /* Fill the entire width of the parent */
  height: 100%; /* Fill the entire height of the parent */
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;   /* Ensure it is above other content but below the overlay */
}

.integrate-calendar-button{
  margin: 12px 8px 0px 8px;
  padding: 4px;
}